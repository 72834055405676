import React,{useState, useEffect} from 'react';
import {NavLink, useHistory} from 'react-router-dom'
import axios from 'axios';
import API_URL from './ApiData'
import { useLocation, Redirect } from 'react-router-dom';

const Header =()=>{
    const [loginStatus, setLoginStatus] = useState(false);
    const locale = 'en';
    const [msg, setMsg] = useState('');
    const [currentPath, setCurrntPage] = useState('');
    const [userNamee, setLoginUsername] = useState('');
    const history = useHistory();
    const [csss, setcsss] = useState('modal fade')
    const [search, setSearch] = useState();
    const [createData, setCreateData] = useState({
      title:'',
      summary :'',
      assign_me : true
    })
    const handleOnChange = event => {
      const { name, value } = event.target;
      setCreateData({ ...createData, [name]: value });
    };
      const location = useLocation();
      const { from } = location.state || { from: { pathname: '/login/' } };
      const mailSendData = (data)=>{
       
         var currentDate = formatDateTime(); 

         var formMailData = new FormData()

         var tempTitle = data.title.replace("\r\n", " ");
         
         formMailData.append('title',"New Story: "+ tempTitle)
         formMailData.append('description', "Created_at : "+currentDate+"\r\n Created_By : "+ localStorage.userName+"\r\n \r\n"+"Summary : "+data.summary)
         //formMailData.append('created_at', data.created_at)
     
         const mailApi = `https://cms.iansnews.in/api/email-before-edit/`; 
         axios({
           method: "post",
           url: mailApi,
           data: formMailData,
           headers: { 
             "Content-Type": "multipart/form-data",
           Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
         },
         }).then((res) => {
           if(res.data && res.data.message && res.data.message == "Email sent successfully"){
            setMsg('')
            setCreateData({
              assign_me : true,
              title : '',
              summary : ''
            })
            setcsss('modal fade');
            setTimeout(
              window.location.href = "/", 10000);
           }
           

         })
    
    } 
    const formatDateTime = () => {
      const dateString = new Date();
      const date = new Date(dateString);
    
      // Extracting parts of the date
      const day = String(date.getDate()).padStart(2, '0'); // Get day and pad it to 2 digits
      const month = date.toLocaleString('default', { month: 'short' }); // Get month as short name
      const year = date.getFullYear(); // Get full year
    
      // Formatting the time
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
      const timeString = new Intl.DateTimeFormat('en-US', options).format(date);
    
      return `${day} ${month} ${year}, ${timeString}`;
    };
      const CreateStory = ()=>{
        var formData = new FormData();
        if(!createData.title){
          setMsg('Title can not be blank.')
        }else if(!createData.summary){
          setMsg('Summary can not be blank.')
        }else{
          formData.append('assign_me', createData.assign_me);
          formData.append('title', createData.title);
          formData.append('summary', createData.summary);
          const getStateApi =API_URL.Create_Story_URL; 
          axios({
            method: "post",
            url: getStateApi,
            data: formData,
            headers: { 
              "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
          },
            
          }).then((res) => {

            setTimeout(
              createLoad(), 50000);
          });
        }
        }
      const createLoad = ()=>{
        // mailSendData(createData, '','')
       mailSendData(createData)
      }
      const openMadal = ()=>{
        setMsg('')
        setcsss('modal fade show');
      }
      useEffect(() => {
        if(!localStorage || !localStorage.accessToken){
            setLoginStatus(true);
            history.push('/login/');
        }
        if(location && location.pathname){
          let setCurPath = location.pathname.split("/");
          if(setCurPath && setCurPath.length > 1){
            setCurrntPage(setCurPath[1] ? setCurPath[1] : 'home');
          }
        }
        if(localStorage && localStorage.accessToken){
          setLoginUsername(localStorage.userName);
          }
      }, [localStorage]);
    return (
        <>
          <nav className="main-header navbar navbar-expand navbar-white navbar-light text-sm">
            <ul className="navbar-nav">
              <li className="nav-user nav-item d-none d-sm-inline-block">
                <i className="fas fa-user"></i> Welcome -- {userNamee} 
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li className="nav-logout nav-item">
              <NavLink className="nav-link"  to="/logout" role="button" >
                <i className="fas fa-sign-out-alt"></i> Logout
              </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
              </li>
            </ul>
          </nav>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="#" className="brand-link text-sm">
              <span className="brand-text font-weight-light">IANS- ADMIN </span>
            </a>
            <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-transition os-host-scrollbar-horizontal-hidden">
              <div className="os-padding">
                <div className="os-viewport os-viewport-native-scrollbars-invisible">
                  <div className="os-content">
                    <nav className="mt-2">
                      <ul className="nav nav-pills nav-sidebar flex-column nav-flat" >
                      { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath == 'home' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/"><i className="nav-icon fas fa-newspaper"></i> <p> All Stories</p></a>
                        </li>
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath =='mystories' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/mystories" ><i className="nav-icon fas fa-user"></i> <p> My Stories</p></a>
                        </li>
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath =='flagged' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/flagged"><i className="nav-icon fas fa-flag"></i> <p> Flagged Stories</p></a>
                        </li>
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath =='pending' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/pending"><i className="nav-icon fas fa-clock"></i> <p> Pending for Review</p></a>
                        </li>
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath =='published' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/published"><i className="nav-icon fas fa-book"></i> <p> Published</p></a>
                        </li>
                      } { localStorage && localStorage.langg && localStorage.langg=='hindi' &&

                       <li className={currentPath =='published-english' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/published-english"> <i className="nav-icon fas fa-file-alt"></i> <p> Published (ENG)</p></a>
                        </li> 
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&
                                           
                        <li className={'nav-link nav-news'} onClick={()=>{
                          openMadal()
                        }}>
                          <a href="#" ><i className="nav-icon fas fa-plus"></i> <p> Add News</p></a>
                        </li>
                      }
                        { localStorage && localStorage.langg && localStorage.langg=='mt' && 
                        <li className={currentPath =='all-pictures' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-pictures"> <i className="nav-icon fas fa-images"></i> <p> All Pictures</p></a>
                        </li> 
                        }
                        { localStorage && localStorage.langg && localStorage.langg=='mt'     &&     
                        <li className={currentPath =='pending-pictures' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/pending-pictures"> <i className="nav-icon fas fa-clock"></i> <p> Pending Pictures</p></a>
                        </li> 
                         }
                         { localStorage && localStorage.langg && localStorage.langg=='mt'     && 
                        <li className={currentPath =='completed-pictures' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/completed-pictures"> <i className="nav-icon fas fa-image"></i> <p> Completed Pictures</p></a>
                        </li> 
                         }
                         { localStorage && localStorage.langg && localStorage.langg=='mt'     &&                                                 
                        <li className={currentPath =='add-new' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/add-new"> <i className="nav-icon fas fa-plus"></i> <p>Add New Event</p></a>
                        </li>}                         
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </aside> 
          <div className={csss} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:csss == 'modal fade'? 'none' : 'block'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add News</h5>
                  <button type="button" className="close" onClick={()=>{
                  setcsss('modal fade')
                }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <p style={{textAlign:'center',fontSize:'18px',color:'red', fontWeight:'500'}}>{msg}</p>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input type="text" className="form-control" placeholder="Enter your title" onChange={handleOnChange} value={createData.title} name="title"/>
                        <div  className="form-text text-danger error" style={{display:'none'}}  >Enter Your Title</div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="shortDesc">Summary</label>
                        <textarea className="form-control form-control-user" placeholder="Summary" onChange={handleOnChange} value={createData.summary} rows="12" name="summary" required=""></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={()=>{
                    CreateStory()
                  }}>Save</button>
                  <button type="button" className="btn btn-secondary" onClick={()=>{
                    setcsss('modal fade')
                  }}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </>
    )
}
export default Header;

