import React from 'react';
import Home from './Home';
import ReactDOM from "react-dom";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Login from './Login';
import StoryEdit from './StoryEdit';
import PendingReview from './PendingReview';
import Flagged from './Flagged';
import PublishedStory from './PublishedStory'
import Mystories from './Mystories'
import Logout from './Logout'
import PublishedStoryEng from './PublishedStoryEng'
import StoryPreview from './StoryPreview'
import AllPictures from './AllPictures'
import EditPicture from './EditPicture'
import AddNewPicture from './AddNewPicture'
import PendingPictures from './PendingPictures'
import CompletedPictures from './CompletedPictures'
import Test from './Test';
const App = () =>{
  return(
    <>
    <BrowserRouter>
    <Switch>
    <Route exact path="/" component={Home}/>
    <Route exact path="/test" component={Test}/>
    <Route exact path="/stories-edit/:editId" component={StoryEdit}/>
    <Route exact path="/login" component={Login}/>
    <Route exact path="/all-pictures" component={AllPictures}/>
    <Route exact path="/completed-pictures" component={CompletedPictures}/>
    <Route exact path="/pending-pictures" component={PendingPictures}/>
    <Route exact path="/add-new" component={AddNewPicture}/>
    <Route exact path="/edit-pictures/:editId" component={EditPicture}/>
    <Route exact path="/logout" component={Logout}/>
    <Route exact path="/published-english" component={PublishedStoryEng}/>
    <Route exact path="/mystories" component={Mystories} />
    <Route exact path="/flagged" component={Flagged} />
    <Route exact path="/pending" component={PendingReview}/>
    <Route exact path="/published" component={PublishedStory}/>
    <Route exact path="/stories-preview/:editId" component={StoryPreview}/>
    <Route exact path="/stories-edit/:editId/:storyId" component={StoryEdit}/>
    <Redirect to="/" />
    </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
