const API_URL={
  Login_URL : 'https://cms.iansnews.in/api/user/login/',
  Create_Story_URL : 'https://cms.iansnews.in/api/stories/create/',
  getUserData : 'https://cms.iansnews.in/api/users/list/?group=',
  Story_URL : 'https://cms.iansnews.in/api/list-all-stories/?language=',
  getStoryData : 'https://cms.iansnews.in/api/version/',
  getStoryCount : 'https://cms.iansnews.in/api/news/count/?language=',
  createAndAssignStory : 'https://cms.iansnews.in/api/stories/create_and_assign_story/',
  getTagData : 'https://cms.iansnews.in/api/stories/tags/',
  fileUploadUrl : 'https://cms.iansnews.in/api/images/multiupload/'

}
export default API_URL