import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {useParams} from 'react-router-dom';
import { Multiselect } from "multiselect-react-dropdown";
import "./style2.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from 'react-router-dom';
import API_URL from './ApiData'
import 'react-quill/dist/quill.snow.css';

const EditPicture =()=>{
    const [isLoading, setIsLoading] = useState(false);
    const [imgModal, setImgModal] = useState(false);
    const [webData, setWebsiteData] = useState();
    const [msg, setMsg]= useState('')
    const [alertCss, setAlertCss]= useState('')
    const [slugUrl, setSlugUrl] = useState('')
    const [cateArry, setCateArry] = useState([]);
    const [tagData, setTagData] = useState();
    const [imgSearch, setImageSeach] = useState('');
    const [stateData, setStateData] = useState();
    const [cattData, setCategoryData] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadImg, setUploadImage] =useState({
      'title':'',
      'keywords':'',
      'caption':'',
      
    })
    const sourceList = [
      "IANS",
      "Abhisek Saha(Agartala)",
      "Abhijit Addya(West Bengal)",
      "Anuwar Hazarika(Assam)",
      "Atish Naik(Goa)",
      "Arun Chandrabos(Kochi)",
      "Ajay Jalandhari(Chandigarh)",
      "Biswanath Swain(Bhubneshwar)",
      "Chandrakant Paddhane(Nagpur)",
      "Hukum Verma(Bhopal)",
      "Nisar Malik(Kahmir)",
      "Nitin Lawate(Mumbai)",
      "Pawan Sharma(Amritsar)",
      "Phool Chandra(Uttar Pradesh)",
      "Rajesh Kumar(Ranchi)",
      "Rameshwar Gaur(Haridwar)",
      "Ravishankar Vyas(Jaipur)",
      "Sanjay Baid(Dharamshala)",
      "Mh Shareef(Jodhpur)",
      "Shaukat Ahmed(Ajmer)",
      "Siddharaj Solaki(Ahmedabad)",
      "Dhananjay(Bengaluru)",
      "Indrajeet(Patna)",
      "Kuntal Chakrabarty(Kolkata)",
      "Parthibhan(Chennai)",
      "Pawan Sharma(Amritsar)",
      "Qamar Sibtain(New Delhi)",
      "Anupam Gautam(New Delhi)",
      "Wasim Sarvar(New Delhi)",
      "Snapsindia(Hyderabad)",
      "Ramana(Hyderabad)",
      "Ramesh Sharma(Noida)"
  ];
    const [selectedOptions, setSelectedOptions] = useState();
    // const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);

    const [selectedStateOptions, setSelectedStateOptions] = useState();

  const handleOnMultiSelectChange = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedOptions(selectedValues);
  };
  const [conData, setValue] = useState('');

  const handleOnStateMultiSelectChange = (event) => {
    const selectedStateValues = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedStateOptions(selectedStateValues);
  };
    // const [selectedStateValues, setSelectedStateValues] = useState([
    //   { slug: "Option 1", cat: "Group 1" },
    //   { slug: "Option 2", cat: "Group 1" }
    // ]);
    // const [selectedValues, setSelectedValues] = useState([
    //   { slug: "Option 1", cat: "Group 1" },
    //   { slug: "Option 2", cat: "Group 1" }
    // ]);
    const style = {
      chips: {
        background: "red"
      },
      searchBox: {
        border: "none",
        borderBottom: "1px solid blue",
        borderRadius: "0px"
      },
      multiselectContainer: {
        color: "red"
      }
    };
  
    // const addItem = () => {
    //   //alert();
    //   setSelectedValues([...selectedValues, { key: "Option 3", cat: "Group 1" }]);
    // };    
    const handleFileChange = (e) => {
      if(e && e.target && e.target.files[0]){
        if(e.target.files[0].type != "image/jpeg" && e.target.files[0].type != "image/jpg"){
          setMsg(`${e.target.files[0].type}  not be allowed.`)
          setAlertCss('alert-error')  
          setShowElement(true)   
          e.target.value = null;

        }
      }
      const files = e.target.files;
      setSelectedFiles([...files]); // Use the spread operator to create a new array
    };
    
    const [userinfo, setUserInfo] = useState({
      languages: [],
      response: [],
    });
    const [searchImg, setSearchImg] = useState({
      name:''
    });
    const [imgSearchD, setSearchImgAry] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [imgArry, setImgAry] = useState([]);
    const [upneImg, setUpImgAry] = useState([])
    const [pageList, setPageList] = useState([1,2,3]);
    const [lt, setLt] = useState(5);
    const {editId, storyId} = useParams();
    const [isChecked, setIsChecked] = useState(false);
    const [isTopChecked, setIsTopChecked] = useState(false)
    const history = useHistory();

    const handleChecked = (event) => {
      setIsChecked(event.target.checked);
    };
    const handleTopChecked = (event)=>{
      setIsTopChecked(event.target.checked);
    }
    const[inputData, setInputData] = useState({
      source:'',
      title: '',
      location:  '',
      content: '',
      tags: '',
      topNews_category: '',
      image_caption:  '',
      top_story: false,
      keywords:  '',
      summary:localStorage.userName ? localStorage.userName : '',
      websites: '',
      states: '',
      images: null,
      slug: ''
  })
    const handleChange = (e) => {
      // Destructuring
      const { value, checked } = e.target;
      const { languages } = userinfo;
        
      console.log(`${value} is ${checked}`);
       
      // Case 1 : The user checks the box
      if (checked) {
        setUserInfo({
          languages: [...languages, value],
          response: [...languages, value],
        });

         
        let newArry = imgSearchD.filter(item => item.id === parseInt(value));
        // let newUpady = imgArry.unshift(newArry[0]);
        // setImgAry(newArry[0], ...newUpady)
        setImgAry(imgArry => [newArry[0], ...imgArry]);
        //imgArry
      }
    
      // Case 2  : The user unchecks the box
      else {
        setUserInfo({
          languages: languages.filter((e) => e !== value),
          response: languages.filter((e) => e !== value),
        });
        // const filteredSrImages = upneImg.filter((e) => e !== value);
        // setUpImgAry(filteredSrImages);
        let newArry = imgArry.filter(item => item.id != parseInt(value));
        setImgAry(newArry);
      }
     // const filteredSImages = userinfo.response.filter(item => item.id === value);
      //setUpImgAry(filteredSImages);
      console.log('IANSNN', imgArry)
    };    

  const [show, setShow] = useState(false);
  const updateImgArry = ()=>{
    console.log('ar',userinfo);

    // setSearchImgAry()
    // setSearchImgAry
    // const upImg= imgArry.push(...userinfo);
    // setImgAry(upImg);
  }
  const [realData, setRealtedData] = useState()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [realtedStory, setRealtedStory] = useState('');
  const [selectedTags, setSelectedTags] = useState(['raju']);
  const handleTagChange = newTags => {
    setSelectedTags(newTags);
  };
  const [lastPage, setLastPage] = useState(10);
  const handleOnMuliSelectChange = event => {
    const selectedOptions = event.target.selectedOptions;
    const selectedValues = Array.from(selectedOptions, option => option.value);
    if(event.target.name && event.target.name == 'states'){
      setInputData({ ...inputData, states: selectedValues });
    }else if(event.target.name && event.target.name == 'websites'){
      setInputData({ ...inputData, websites: selectedValues });
    }
  };
  const handleOnChange = event => {
    const { name, value } = event.target;
    setInputData({ ...inputData, [name]: value });
  };
  const handleUpload = event =>{
    const { name, value } = event.target;
    setUploadImage({ ...uploadImg, [name]: value });
  }
  const handleOnImgChange = event =>{
    const { name, value } = event.target;

    setSearchImage({ ...searchImg, [name]: value })
  }
  const [currentImgPage, setImgCurrentPage] = useState(1);
  const [searchImage, setSearchImage] = useState('');
  const [allStoryList, SetAllStoryData] = useState([]);
  const handleImgNextPage = (curr) => {
    //if(lastPage > currentPage){
      setImgCurrentPage(curr);
      //if(currentImgPage > 1){
        getSearchImg(curr);
      // }
      // let newArry = pageList;
      // let newAddArray = pageList[pageList.length-1]+1;
      // // if(currentPage > 2){
      //   let newRemoveArray = pageList[0];
      // //
      // // }
      // newArry.shift(newRemoveArray);
      // newArry.push(newAddArray);
      //   setPageList(newArry);
    
        //}
    }
  const handleNextPage = () => {
    if(lastPage > currentPage){
      setCurrentPage(currentPage + 1);
      let newArry = pageList;
      let newAddArray = pageList[pageList.length-1]+1;
      // if(currentPage > 2){
        let newRemoveArray = pageList[0];
      //
      // }
      newArry.shift(newRemoveArray);
      newArry.push(newAddArray);
        setPageList(newArry);
    
        }
    }
  const getSearchImg = (page)=>{
    // event.preventDefault();
    if(page && page == 1){
    setImgCurrentPage(1);
    }

    if(searchImage.name){
      setShow(true);
      const imSearchAPI = `https://cms.iansnews.in/api/elastic/image/search/?query=${searchImage.name}&page=${page}`;
      axios.get(
        imSearchAPI
      ).then((res) => {
      const allImgData =
          res.data.data &&
          res.data.data.length > 0 &&
          res.data.data.map((item) => {
          return {
              id: parseInt(item.id),
              title: item.title,
              image: item.thumbnail,
              caption: item.caption,
              keywords:item.keywords 
          };
          });
      if(res && res.data && res.data.data && res.data.data.length > 0 ){
        setSearchImgAry(allImgData);
        console.log('vbb',imgSearchD);
      }

      //console.log('TMT-varun', res);
      });
    }
  
  
  }      
  const handlePrevPage = () => {
  if(currentPage > 1){
  
    setCurrentPage(currentPage - 1);
    // setCurrentPage(currentPage + 1);
    let newArry = pageList;
  
    let newAddArray = pageList[pageList.length-1];
    let newRemoveArray = pageList[0]-1;
      newArry.unshift(newRemoveArray);
    newArry.pop(newAddArray);
  setPageList(newArry);
  }
  }
  const handleImgPrevPage = (curr)=>{
    if(curr > 0){
      setImgCurrentPage(curr);
      getSearchImg(curr);
    }
  }
  const handleLastPage = () => {
  //if(requestFlag){
    setCurrentPage(lt);
    let lastArrtty = [lt-3, lt-2, lt-1]
    setPageList(lastArrtty);
  //  }
  }
  const removeImage =(id)=>{
    const filteredImages = imgArry.filter(item => item.id != id);

    //imgArry.filter((filterImg, f)==)
    setImgAry(filteredImages);
    //alert(id)
  }
  const handleStartPage = () => {
  // if(requestFlag){
    setCurrentPage(1);
    let lastArrtty = [1, 2, 3]
    setPageList(lastArrtty);
  //  }
  }
  const handleSetPage = (page) => {
  //  if(requestFlag){
    setCurrentPage(page);
  //}
  }
  const fetchData = async (page, ln) => {
    ln = ln =='ce' ? 'english' :ln;
    
    const allStoryAPI =`https://cms.iansnews.in/api/images/${editId}`; 

    


    
      axios
      .get(
          allStoryAPI
      )
      .then((res) => {
      if(res && res.data && res.data.data){
        setInputData({
          source:res.data.data.source ? res.data.data.source :'IANS',
          title:res.data.data.title ? res.data.data.title :'',
          location:  res.data.data.location ? res.data.data.location :'',
          content: res.data.data.content ? res.data.data.content :'',
          tags: '',
          topNews_category: res.data.data.topNews_category ? res.data.data.topNews_category : '',
          image_caption:  res.data.data.image_caption ? res.data.data.image_caption :'',
          top_story: res.data.data.top_story,
          keywords:  res.data.data.keywords ? res.data.data.keywords :'',
          summary:  res.data.data.summary ? res.data.data.summary :'',

          websites: '',
          states: '',
          images: null,
          slug: res.data.data.slug ? res.data.data.slug :''
        })
        setValue(res.data.data.content ? res.data.data.content :'')
        setIsChecked(res.data.data.top_story);
      }
      if(res && res.data && res.data.data && res.data.data.images && res.data.data.images.length > 0 ){
        setImgAry(res.data.data.images);
        console.log('vvv', res.data.data.images)
      }
      
      if(res && res.data && res.data.data && res.data.data.tags){
        
        let t12 = res.data.data.tags.split(",");
        const net = t12 && t12.length > 0 && t12.map((tt, t)=>{
          return {
            id: t,
            slug: tt,
            name: tt

          }
        }) 

        setTagData(net);
      }
      if(res && res.data && res.data.data && res.data.data.websites){

        //let tww12 = res.data.data.websites.split(",");
        let stringArray = res.data.data.websites.length > 0 && res.data.data.websites.map(item => item.name);
        setSelectedOptions(stringArray);
      } 
      if(res && res.data && res.data.data && res.data.data.states){
        // let twwv12 = res.data.states.split(",");
        let twwv12 = res.data.data.states.length > 0 && res.data.data.states.map(item => item.id);
        setSelectedStateOptions(twwv12);
      }
            
      SetAllStoryData(res.data.data);
      if(res.data.data.related_story){
        setRealtedStory(res.data.data.related_story);
        // getRealtedStoryData(res.data.data.related_story);
      }
      //console.log('TMT-varun', res);
      });

  }
  const getRealtedStoryData = (realId)=>{
    const getWebApi =`https://cms.iansnews.in/api/list-all-stories/?story_id=${realId}`; 
      axios
      .get(
        getWebApi
      )
      .then((res) => {
        if(res && res.data && res.data.data && res.data.data.length > 0){
          setRealtedData(res.data.data[0]);
        }
      });      
  }
  const getWebSiteData = ()=>{
    const getWebApi =`https://cms.iansnews.in/api/websites/list/`; 

      axios
      .get(
        getWebApi
      )
      .then((res) => {
      const webD =
          res.data.data &&
          res.data.data.length > 0 &&
          res.data.data.map((item) => {
          return {
              id: item.id,
              description: item.description,
              name: item.name
          };
          });
      setWebsiteData(webD);
      });      
  }
  const getStateData = ()=>{
    const getStateApi =`https://cms.iansnews.in/api/state/list/`; 

      axios
      .get(
        getStateApi
      )
      .then((res) => {
      const stateD =
          res.data.data &&
          res.data.data.length > 0 &&
          res.data.data.map((item) => {
          return {
              id: item.id,
              code: item.code,
              name: item.name
          };
          });
          setStateData(stateD);
      });      
  }  
  const getCategoryData = ()=>{
      axios.get(API_URL.getTagData).then((res) => {
      const tagsD =
          res.data &&
          res.data.length > 0 &&
          res.data.map((item) => {
          return {
              id: item.id,
              slug: item.slug,
              name: item.name
          };
          });
          setCategoryData(tagsD);
      });      
  }
  const SaveStoryData = ()=>{
    if(!inputData.source){
        alert('Source can not be blank.');
        return false;
    }
    //const tggg = selectedList;
    setMsg('')

    //console.log(tagData, 'varun');
    setIsLoading(true);
    let nameArray = tagData && tagData.length > 0 && tagData.map(item => item.slug); // Extract 'name' property from each object

    let resultString = nameArray && nameArray.join(',').replace(/\s/g, '');
      var formData = new FormData();
      let imgArray = imgArry && imgArry.length > 0 && imgArry.map(item => item.id); // Extract 'name' property from each object
      let resultImgString = imgArray && imgArray.join(',').replace(/\s/g, '');
      formData.append('title', inputData.title);
      formData.append('summary', inputData.summary);
      formData.append('keywords', inputData.keywords);
      formData.append('location', inputData.location);
      formData.append('tags', resultString);
      formData.append('source', inputData.source);
      // formData.append('top_story', inputData.top_story);
      formData.append('topNews_category', inputData.topNews_category);
      // formData.append('image_caption', inputData.image_caption);
      formData.append('top_story', isChecked);
      // formData.append('websites', selectedOptions);
      // formData.append('states', selectedStateOptions);
      formData.append('image_ids', resultImgString);
      // formData.append('slug', inputData.slug);
      const getStateApi =`https://cms.iansnews.in/api/images/create/`; 
// const config = {
//   headers: {
//     Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
//   },
// };
axios({
  method: "post",
  url: getStateApi,
  data: formData,
  headers: { 
    "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

}}).then((res) => {
  if(res && res.data && res.data.data && res.data.message){
    //alert(res.data.message)
    setSlugUrl(res.data.message)
    //setMsg(res.data.message)
    setAlertCss('alert-success')
    setShowElement(true)
    history.push('/edit-pictures/'+res.data.data.uuid);
  }

  
  console.log(res,'cccccccccc');
  setIsLoading(false);
// const stateD =
//     res.data.data &&
//     res.data.data.length > 0 &&
//     res.data.data.map((item) => {
//     return {
//         id: item.id,
//         code: item.code,
//         name: item.name
//     };
//     });
    //setStateData(stateD);
}) .catch((error) => {
  if(error && error.response && error.response.data && error.response.data.detail){
    alert(error.response.data.detail);
  }
  console.error("Error uploading image: ", error);
});
setTimeout(function() {
  setShowElement(false);
}, 2000);
    

  }
  const SubmitStoryData = ()=>{
    setIsLoading(true);

    //const tggg = selectedList;
    console.log(tagData, 'varun');
    var formData = new FormData();

    formData.append('title', inputData.title);



const getSubmitApi = `https://cms.iansnews.in/api/images/${editId}/submit/`; 

axios({
method: "post",
url: getSubmitApi,
data: formData,
headers: { 
  "Content-Type": "multipart/form-data",
Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

},

}).then((res) => {
setIsLoading(false);
history.push('/');


  
});
  } 
  const FlagStoryData = ()=>{
    setIsLoading(true);
    //const tggg = selectedList;
    console.log(tagData, 'varun');
    var formData = new FormData();

    formData.append('flag_message', 'falgged');


const flagApi = ` https://cms.iansnews.in/api/images/${editId}/flagged/`;

axios({
method: "post",
url: flagApi,
data: formData,
headers: { 
  "Content-Type": "multipart/form-data",
Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

},

}).then((res) => {
setIsLoading(false);
history.push('/');
  
});
  }  
  const uploadImage = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Loop through selected files and upload them one by one
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('images', selectedFiles[i]);
      formData.append("title", uploadImg.title);
      formData.append("keywords", uploadImg.keywords);
      formData.append("caption", uploadImg.caption);
      // formData.append("headline", isTopChecked ? "true": "false");
    }
      const getUploadImgApi = API_URL.fileUploadUrl;
      setIsLoading(true)
      axios({
        method: "post",
        url: getUploadImgApi,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if(res && res.data && res.data.data && res.data.data.length > 0){
              const newImage =
                      res.data.data &&
                      res.data.data.length > 0 &&
                      res.data.data.map((item) => {
                      return {
                        id:item.id ? item.id : "",
                        url: item.image ? item.image : "",
                        keywords: item.keywords ? item.keywords :"",
                        title: item.title ? item.title : "",
                        caption:item.caption ? item.caption :""
                      }
                      });
            // Add the new image to the image array
            if(imgArry && imgArry.length > 0){
              // let bewwww = imgArry.push(newImage);
              // setImgAry((imgArry) => [newImage, ...imgArry]);
              const mergedArray = imgArry.concat(newImage);

              setImgAry(mergedArray);
            }else{
              setImgAry(newImage);
            }
            setImgModal(false);
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.error("Error uploading image: ", error);
          setIsLoading(false)
        });
    // });
  
    // Clear the form input fields and selected files
    setUploadImage({
      title: "",
      keywords: "",
      caption: "",
    });
    setSelectedFiles([]);
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
    const PublishStoryData = ()=>{
      setMsg('');
      let nameArray = tagData && tagData.length > 0 && tagData.map(item => item.slug); // Extract 'name' property from each object
  
      let resultString = nameArray && nameArray.join(',').replace(/\s/g, '');
      if(!inputData.title){
        //setMsg('Heading Title can not be blank.')
        //alert('News Heading can not be blank.')
        setMsg('News Heading can not be blank.')
        setAlertCss('alert-error')
        setShowElement(true)
      // }else if(!inputData.slug){
      //   //alert('Slug can not be blank.')
      //   setMsg('Slug can not be blank.')
      //   setAlertCss('alert-error')
      //   setShowElement(true)
      }else if(!inputData.location){
        //alert('Short Description can not be blank.')
        setMsg('Short Description can not be blank.')
        setAlertCss('alert-error')
        setShowElement(true)
      // }else if(!conData){
      //   //alert('Content can not be blank.')
      //   setMsg('Content can not be blank.')
      //   setAlertCss('alert-error')
      //   setShowElement(true)
      }else if(!resultString){
        //alert('Category can not be blank.')  
        setMsg('Category can not be blank.')
        setAlertCss('alert-error')  
        setShowElement(true)   
      }else{

      
      //const tggg = selectedList;
      setIsLoading(true);

const getpublishApi = `https://cms.iansnews.in/api/images/${editId}/publish/`;

      axios({
        method: "post",
        url: getpublishApi,
        headers: { 
          "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

      }}).then((res) => {
        setIsLoading(false);
        history.push('/');
      });
      WebPublishStoryData();
    }
}   
//const [showElement,setShowElement] = useState(true)

    const WebPublishStoryData = ()=>{
    // const getwebpublishApi = `https://cms.iansnews.in/api/stories/${editId}/webpublish/`; 
    const getwebpublishApi = `https://cms.iansnews.in/api/images/${editId}/webpublish/`;
    axios({
      method: "post",
      url: getwebpublishApi,
      headers: { 
        "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
    },
    }).then((res) => {

    });
    }  
     const [showElement, setShowElement] = useState(false);

      useEffect(() => {
        // if(localStorage && localStorage.langg  && (localStorage.langg=='hindi' || localStorage.langg=='ce')){
        //   fetchData(currentPage, localStorage.langg);
        // }
        setTimeout(function() {
          setShowElement(false);
          }, 3000);

        // getWebSiteData();
        // getStateData();
        getCategoryData();
        // if(realtedStory){
        //   getRealtedStoryData(realtedStory);
        // }
      }, [currentPage, realtedStory, msg]);
       
    return(
        <>
            <div className="wrapper">
              <Header />
                <div className="content-wrapper">
                  <div className="content-header">
                    <div className="container-fluid">
                      <div className="row mb-2">
                        <div className="col-sm-10">
                          <h1 className="m-0">New Event</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="content">
                    <div className="container-fluid">
                      <div className="col-lg-12">
                        <div className="card card-outline card-primary">
                          <div className="card-body">
                            <div className="col-md-12">																	
                              <div className="form-group">
                                <label htmlFor="title">Event Title</label>
                                <input type="text" onChange={handleOnChange} name="title" className="form-control" id="inputHeading" value={inputData.title}/>
                              </div>
                              <div className="form-group">
                                <label htmlFor="keyword">Keyword</label>
                                <input type="text" className="form-control" onChange={handleOnChange} name="keywords" value={inputData.keywords}/>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-4">
                                  <label htmlFor="location">Location</label>
                                  <input type="text" className="form-control" onChange={handleOnChange} name="location" value={inputData.location}/>
                                </div>
                                <div className="form-group col-md-4">
                                  <label htmlFor="initial">Initial</label>
                                  <input type="text" className="form-control" onChange={handleOnChange} name="summary" value={inputData.summary} readOnly/>
                                </div>
                                <div className="form-group col-md-4">
                                  <label htmlFor="source">Source</label>
                                  <select className="form-control" name="source" onChange={handleOnChange} value={inputData.source} required>
                                    <option selected="" value="">-- Select Source --</option>
                                    {sourceList && sourceList.length > 0 && sourceList.map((itemSour, sou)=>{
                                      return(
                                        <option value={itemSour} key={sou}>{itemSour}</option>
                                      )
                                    })}
                                  </select>
                                  {/* <input type="text" className="form-control" onChange={handleOnChange} name="source" value={inputData.source}/> */}
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-check">
                                <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleChecked}
                                className="form-check-input"
                              />
                                  <label className="form-check-label" htmlFor="gridCheck">
                                  Top Story
                                  </label>
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="tags">Category</label>
                                  <Multiselect
                                  options={cattData}
                                  displayValue="slug"
                                  selectedValues={tagData}
                                  onSelect={(selectedList) => setTagData(selectedList)}
                                  onRemove={(selectedList) => setTagData(selectedList)}
                                />                                  
                                </div>
                                {/* <div className="form-group col-md-6">
                                  <label htmlFor="topCat">Top News Category</label>
                                      <select className="form-control" name="topNews_category" onChange={handleOnChange} value={inputData.topNews_category}>
                                    <option selected="">-- Select Top News Category --</option>
                                    {cattData && cattData.length > 0 && cattData.map((catt, c)=>{
                                      return(
                                        <option value={catt.slug} key={c}>{catt.name}</option>
                                      )
                                    })}
                              </select>
                                </div> */}
                              </div>
                              <div className="accordion" id="morePhoto">
                                <button className="addimg-btn btn btn-primary" type="button" onClick={()=>{
                                    setImgModal(!imgModal)
                                  }}>
                                + Add Images
                                </button>
                                {isLoading     && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>  
                              }	
                                <div className="accordion" id="morePhoto">
                          {imgModal &&
                            <div>
                              <div className="card card-body">
                              <form encType="multipart/form-data" className="border p-2 mb-2 rounded">
                             
                                  <div className="form-row">
                                    <div className="form-group col-md-2">
                                      <label htmlFor="files">Photos</label>
                                      <input
                                        type="file"
                                        onChange={handleFileChange}
                                        name="image"
                                        multiple
                                        required
                                        accept="image/jpeg, image/jpg"/>
                                    </div>
                                    {/* <div className="form-group col-md-4">
                                    
                                      <input
                                        type="checkbox"
                                        checked={isTopChecked}
                                        onChange={handleTopChecked}
                                      />
                                       <label htmlFor="imageCaption"> <span>&nbsp;</span> Top Image</label>
                                    </div>      */}

                                    <div className="form-group col-md-10">
                                      <label htmlFor="imageKeyword">Image Keyword</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="keywords"
                                        onChange={handleUpload}
                                        value={uploadImg.keywords}
                                        placeholder="Enter your image keywords"
                                        required
                                      />
                                    </div>
                                    
                                    <div className="form-group col-md-12">
                                      <label htmlFor="imageCaption">Image Caption</label>
                                      <textarea
                                        className="form-control"
                                        name="caption"
                                        onChange={handleUpload}
                                        value={uploadImg.caption}
                                        placeholder="Enter caption"
                                      />
                                    </div>
                                   
                                  </div>
                                  <div className="form-row">
                                    <button className="btn btn-success" onClick={uploadImage}>
                                      Add
                                    </button>
                                    <button className="btn btn-secondary" type="button">
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>}  
                            </div>
                              </div>
                              <ul className="pending-pic">
                              {imgArry && imgArry.length > 0 && imgArry.map((imgg, im)=>{
                                    return(
                                  <li key={im}>
                                    <img src={imgg.url}/>
                                    <div className="editpho-icon">
                                      <a href={imgg.image} onClick={()=>{
                                              removeImage(imgg.id)
                                      }}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                      </a>
                                    </div>
                                    <p className='img-name'>{imgg.url  ? imgg.url.split[1] :''}</p>
                                    <p>{imgg.caption}</p>
                                  </li>                            
                                    )
                                  })} 
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-4">
                          <button className="btn btn-secondary savebtn" type="button" onClick={SaveStoryData}>Save</button>
                        </div>                        
                      </div>        
                    </div>
                  </section>
                </div>
              <Footer />
            </div>
        </>
    )
}

export default EditPicture